export enum LangCode {
  DeDE = 'de_DE',
  EnUK = 'en_UK',
  EsES = 'es_ES',
  FrFR = 'fr_FR',
  ItIT = 'it_IT',
}

export enum Languages {
  de_DE = 'Deutsch',
  en_UK = 'English',
  es_ES = 'Español',
  fr_FR = 'Français',
  it_IT = 'Italiano',
}
