import { Injectable, signal } from '@angular/core';
import { AbstractApiService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigResponse } from '../core/types/config-response.type';
import { LangCode } from '../core/types/langCode.enum';

@Injectable({ providedIn: 'root' })
export class ConfigurationService extends AbstractApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  configSubject = new BehaviorSubject<ConfigResponse | null>(null);
  config$ = this.configSubject.asObservable();

  langCodeSubject = new BehaviorSubject<LangCode>(LangCode.ItIT);
  langCode$ = this.langCodeSubject.asObservable();

  currentPageSubject = new BehaviorSubject<string>('');
  currentPage$ = this.langCodeSubject.asObservable();

  propertyCodeSubject = new BehaviorSubject<string>('');
  propertyCode$ = this.propertyCodeSubject.asObservable();

  logoUrl = signal('');
  config!: ConfigResponse | null;

  setLangCode(langCode: LangCode): void {
    if (langCode) this.langCodeSubject.next(langCode);
  }

  setPropertyCode(propertyCode: string): void {
    if (propertyCode) this.propertyCodeSubject.next(propertyCode);
  }

  getLogoUrl(): string {
    return this.config?.property?.logoUrl || '';
  }

  setLogoUrl(logoUrl: string): void {
    this.logoUrl.set(logoUrl);
  }

  getConfigValue() {
    return this.config;
  }

  setConfig(config: ConfigResponse): void {
    this.configSubject.next(config);
    this.config = config;
  }

  getConfig(propertyCode: string, langCode: string): Observable<ConfigResponse> {
    const url = `${this.getBaseUrl()}/config/${propertyCode}/${langCode}.json`;
    return this.http.get<ConfigResponse>(url);
  }
}
