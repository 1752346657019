//eclude from jest coverage
/* istanbul ignore file */
import { environment } from '../../environments/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export abstract class AbstractApiService {
  apiHost: string | undefined = environment.api_host;
  apiGetReservationUrl: string | undefined = environment.getReservationUrl;
  apiPostGuestUrl: string | undefined = environment.postGuestUrl;
  apiProtocol: string | undefined = environment.protocol;

  protected constructor(protected http: HttpClient) {}

  getBaseUrl() {
    return `${this.apiProtocol}://${this.apiHost}`;
  }

  getGetReservationUrl() {
    return `${this.apiGetReservationUrl}`;
  }

  getPostGuestUrl() {
    return `${this.apiPostGuestUrl}`;
  }

  getHeaders() {
    let headers: HttpHeaders = this.getAuthHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  setHeaders(key: string, value: string) {
    let headers = this.getAuthHeaders();
    if (headers.has(key)) {
      headers = headers.delete(key);
    }
    headers = headers.append(key, value);
    return headers;
  }

  getAuthHeaders() {
    const token = sessionStorage.getItem('token');
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
}
